@import "~@ic-anywhere/ic-components/dist/ic-components.css";

@media (max-width: 992px) {
  .corporate h1,
  .investor h1 {
    font-size: 1.625rem;
    font-weight: 800;
    line-height: 1.0769230769;
    letter-spacing: -0.025em;
  }
}

@media screen {
  .roadshow-print {
    display: none;
  }
}

@media print {
  .socgen-color {
    color: #e60028;
  }

  .break-inside-avoid {
    break-inside: avoid;
  }

  .roadshow-container {
    display: none !important;
  }

  .roadshow-print {
    display: block;
  }

  .roadshow-print .slot-location {
    text-decoration: none;
  }

  .roadshow-invitations {
    break-before: page;
  }
}

:global html,
:global body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

:global #root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

:global #root .main {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  min-height: 82vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

:global #root .main>div {
  width: 100%;
  flex-shrink: 0;
  flex-basis: auto;
}

:global #root .container {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-content: stretch;
}

:global #root .container>div {
  width: 100%;
  flex-shrink: 0;
  flex-basis: auto;
}

:global #root .container-header {
  align-content: stretch;
  overflow-y: hidden;
}

:global #root .container-header>div {
  width: 100%;
  flex-shrink: 0;
  flex-basis: auto;
}

:global #root .container-header>h1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:global #root sgwt-mini-footer {
  align-self: flex-end;
  align-self: stretch;
}

:global header,
:global footer {
  flex-shrink: 0;
}

:global .pdf-root-container {
  margin-left: 1.5rem !important;
}

:global .nav-header {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
}

:global .nav-header::-webkit-scrollbar {
  height: 1px;
}

:global .nav-header::-webkit-scrollbar-thumb {
  background: #6e777a;
}

:global .icon .icon-sg-event {
  width: 24px;
}

:global .icon .icon-sg-event.icon-sm {
  width: 18px;
}

:global .icon .icon-sg-event.icon-lg {
  width: 32px;
}

:global .icon .icon-sg-event.icon-xl {
  width: 48px;
}

:global .sb-avatar__text>div {
  font-size: medium !important;
}

:global .card-header [data-toggle=collapse]:not(.collapsed) .icon {
  transform: rotate(90deg);
}

@media (min-width: 992px) {
  :global .section-1 {
    background: linear-gradient(90deg, #fff 50%, rgba(250, 250, 250, 0) 0);
  }

  :global .section-2,
  :global .section-3 {
    background-size: 6px 6px;
    background-image: linear-gradient(-45deg, #fafafa 46%, rgba(48, 51, 51, 0.7) 49%, rgba(48, 51, 51, 0.7) 51%, #fafafa 55%);
  }

  :global .section-2>.container-fluid {
    background: linear-gradient(90deg, #ffffff 50%, rgba(250, 250, 250, 0) 0);
  }

  :global .section-3>.container-fluid {
    background: linear-gradient(90deg, #fafafa 50%, rgba(250, 250, 250, 0) 0);
  }
}

:global .text-outline-socgen {
  text-shadow: -1px 0 #e60028,0 1px #e60028,1px 0 #e60028,0 -1px #e60028;
}

@media all and (-ms-high-contrast: none),(-ms-high-contrast: active) {
  .card {
    flex: 1 0 100%;
  }

  .sgbs-timeline-item {
    flex: none;
  }
}

